import axios from 'axios';
import {generateCurdManager} from "@/store/baseManager";
import {actionTypes} from "@/store/actionTypes";
import {getCode, getCodeByCodeTypeAndName} from "@/store/modules/dictionaryCode";
import {dictionaryCodeType} from "@/store/enum";

export const baseUrl = '/wcxx';

const store = generateCurdManager({
    namespaced: true,
    url: baseUrl,
    listProperty: 'wcxxList',
    serverPagination: true,
});

const {actions} = store;

export const needColumns = {
    nf: '年份',
    sfId: '省份',
    klId: '科类',
    fs: '分数',
    bdrs: '本段人数',
    ljrs: '累计人数',
    bz: '备注',
};

const parseToInt = (value) => {
    if (value == null) {
        return null;
    }

    if (_.isInteger(value)) {
        return value;
    }

    if (_.isString(value) && /^\d+$/.test(value)) {
        return _.parseInt(value);
    }

    return null;
};

// 一行一个分数段
const parseLineRecorder = (rootState, columns, recorders) => {
    const temp = _.map(recorders, (n) => {
        const r = {};
        _.forEach(_.keys(needColumns), (k, index) => {
            const value = n[needColumns[k]];

            // 对分数字段做处理，如果不是int类型，就要做处理
            if (k === 'fs') {
                const fs = parseFs(value);
                if (fs) {
                    r[k] = fs;
                } else {
                    throw new Error(`无法解析分数(第${index + 1}行)：${value}`)
                }
            } else if (k === 'sfId') {
                r[k] = getCodeByCodeTypeAndName(rootState, dictionaryCodeType.Province, value, true).id;
            } else if (k === 'klId') {
                r[k] = getCodeByCodeTypeAndName(rootState, dictionaryCodeType.KL, value, true).id;
            } else if (k === 'bz' && (value == null || value === '') && r.bz != null) {
                // 这种情况下，什么也不要做
                return true;
            } else {
                r[k] = value;
            }
        });
        return r
    });

    const result = [];
    _.forEach(temp, r => {
        if (_.isArray(r.fs)) {
            const bz = `${r.bz ? r.bz + '   ' : ''}${_.max(r.fs)}-${_.min(r.fs)}`;
            for (let i = 0; i < r.fs.length; i++) {
                const t = {};
                Object.assign(t, r);
                t.fs = r.fs[i];
                t.bz = bz;
                result.push(t);
            }
        } else {
            result.push(r);
        }
    });

    return result;
};

const parseFs = (fs) => {
    if (fs == null) {
        throw new Error('分数不能为空');
    }


    if (parseToInt(fs)) {
        return fs;
    }

    fs = fs.toString();
    if (fs.includes('-')) {
        const split = _.split(fs, '-');
        if (split.length === 2) {
            let [left, right] = split;
            left = _.parseInt(left);
            right = _.parseInt(right);
            if (!isNaN(left) && !isNaN(right)) {
                return _.range(_.min([left, right]), _.max([left, right]) + 1);
            }
        }
    }

    fs = _.toString(fs).match(/\d+/)?.[0];

    if (fs) {
        return fs;
    }

    return null;
};

const matrixColumns = {
    0: '+0',
    1: '+1',
    2: '+2',
    3: '+3',
    4: '+4',
    5: '+5',
    6: '+6',
    7: '+7',
    8: '+8',
    9: '+9',
    fs: '分数',
};

const parseMatrixRecorder = (recorders, nf, sf, kl) => {
    const result = [];
    _.forEach(recorders, r => {
        const rawFs = r[matrixColumns.fs];
        let fs = parseToInt(rawFs);
        if (fs == null) {
            throw new Error('无法解析分数：' + r[matrixColumns.fs]);
        }
        for (let i = 0; i < 10; i++) {
            const temp = {
                fs: fs + i,
                ljrs: r[`+${i}`],
                nf,
                sfId: sf,
                klId: kl,
            };

            if (temp.ljrs) {
                temp.ljrs = parseToInt(temp.ljrs);
                if (temp.ljrs == null) {
                    throw new Error(`无法解析 ${fs} +${i}`);
                }
                result.push(temp);
            }
        }
    });
    return result;
};

const checkExcelData = (columns, needColumns, recorders) => {
    const difference = _.difference(needColumns, columns);
    if (difference.length > 0) {
        throw new Error('缺少必须的列：' + difference);
    }

    if (recorders.length === 0) {
        throw new Error('没有记录可以导入');
    }
};

actions[actionTypes.wcxx.import] = async ({dispatch, rootState}, file) => {
    const {columns, recorders} = await dispatch(actionTypes.common.parseExcel, file, {root: true});

    let isMatrix = columns.includes(matrixColumns["9"]);
    let parsedRecorders;
    if (isMatrix) {
        let [nf, sf, kl] = file.name.split('.')[0].split('_');
        nf = parseToInt(nf);
        if (nf == null) {
            throw new Error(`无法解析年份：${nf}`);
        }

        sf = getCodeByCodeTypeAndName(rootState, dictionaryCodeType.Province, sf, true).id;
        kl = getCodeByCodeTypeAndName(rootState, dictionaryCodeType.KL, kl, true).id;

        checkExcelData(_.values(matrixColumns), columns, recorders);
        parsedRecorders = parseMatrixRecorder(recorders, nf, sf, kl);
    } else {
        checkExcelData(_.values(needColumns), columns, recorders);
        parsedRecorders = parseLineRecorder(rootState, columns, recorders);
    }

    const groupByNf = _.groupBy(parsedRecorders, n => n.nf);
    if (_.keys(groupByNf).length !== 1) {
        throw new Error(`有超过一个年份的数据：${_.keys(groupByNf)}`);
    }

    const groupBySf = _.groupBy(parsedRecorders, n => n.sfId);
    if (_.keys(groupBySf).length !== 1) {
        throw new Error(`有超过一个省份的数据：${_.keys(groupBySf).map(n => getCode(rootState, n).name)}`);
    }

    await axios.post(`${baseUrl}/import`, parsedRecorders, {formPost: true});
};

actions[actionTypes.wcxx.export] = async ({dispatch, rootState}, query) => {
    const q = {
        nf: query.nf,
        sfId: query.sfId,
        klId: query.klId,
        sheetName: `${query.nf}年${query.sfId ? '_' + getCode(rootState, query.sfId).name + '省' : ''}${query.klId ? '_' + getCode(rootState, query.klId).name : ''}_位次信息`,
        columnKeys: _.keys(needColumns),
        columns: null,
    };
    q.columns = _.map(q.columnKeys, k => needColumns[k]);
    const {data} = await axios.post(`${baseUrl}/export`, q);
    dispatch(actionTypes.common.openDownloadLink, data, {root: true});
};

export default store;
