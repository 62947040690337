import dayjs from 'dayjs';
import {debounceFn} from './debounce';

/**
 * 格式化输出一个moment对象
 * @param {moment} input
 * @return {string}
 */
export const renderMomentDateTime = (input) => {
    return input.format('YYYY-MM-DD HH:mm:ss');
};

/**
 * 格式化输出一个unix time stamp
 * @param {number} input
 * @param {string} defaultValue
 * @return {string}
 */
export const renderUnixTimeStamp = (input, defaultValue = '-') => {
    return input ? renderMomentDateTime(dayjs(input * 1000)) : defaultValue;
};

export const renderBoolean = (value, reverse = false) => {
    if (reverse) {
        return !value ? '是' : '否';
    } else {
        return value ? '是' : '否';
    }
};

export const generatePostData = (data) => {
    return {data: JSON.stringify(data)};
};

export const createDebounce = (func, wait = null) => {
    return debounceFn(func, wait || process.env.VUE_APP_DEB || 200);
};

export const parseInsertResponse = (response) => {
    return response.data.success ? JSON.parse(response.data.message).id : null;
};

export const traverseTree = (tree, parent, nodeCreator, result, leafNodeCreator) => {
    if (result.length === 0) {
        const rootLevel = _.filter(tree, (n) => parent == null ? n.parentId == null : n.parentId === parent.id);
        _.forEach(rootLevel, (node) => {
            const n = nodeCreator(node);
            result.push(n);
            if (node.children) {
                traverseTree(node.children, n, nodeCreator, result, leafNodeCreator);
            } else if (leafNodeCreator) {
                const leafChildren = leafNodeCreator(n);
                if (leafChildren && leafChildren.length > 0) {
                    n.children = leafChildren;
                }
            }
        });
    } else {
        parent.children = [];
        _.forEach(tree, (node) => {
            const n = nodeCreator(node, parent);
            // 把原有的子节点插入到最前面
            parent.children.splice(0, 0, n);
            if (node.children) {
                traverseTree(node.children, n, nodeCreator, result, leafNodeCreator);
            } else if (leafNodeCreator) {
                const leafChildren = leafNodeCreator(n);
                if (leafChildren && leafChildren.length > 0) {
                    n.children = leafChildren;
                }
            }
        });
    }
};

export const getTableColumns = (tableRef) => {
    if (!tableRef) {
        throw new Error('table ref is null');
    }

    const headerWrapper = tableRef.$el.getElementsByClassName('el-table__header-wrapper');
    if (!headerWrapper || headerWrapper.length === 0) {
        throw new Error('can not find "el-table__header-wrapper"');
    }

    const cells = headerWrapper[0].getElementsByClassName('cell');
    const columns = _.map(cells, 'innerText');
    console.log(`get columns: ${columns}`);
    return columns;
};
