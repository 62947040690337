export const actionTypes = {
    common: {
        list: 'list', // 更新列表数据
        add: 'add', // 新增
        edit: 'edit', // 更新
        remove: 'remove', // 删除
        get: 'get', // 获取一条数据,
        checkUnique: 'checkUnique', // 检查重名,
        exportExcel: 'exportExcel', // 导出Excel表
        parseExcel: 'parseExcel', // 读取excel文件
        parseDbf: 'parseDbf', // 读取dbf文件
        parseFile: 'parseFile', // 读取文件
        openDownloadLink: 'openDownloadLink', // 打开下载链接
        getWebSettings: 'getWebSettings', // 获取网站配置信息
        downloadTemplate: 'downloadTemplate', // 下载模板
        import: 'import',
        export: 'export',
    },
    auth: {
        getCurrentUser: 'getCurrentUser',
        login: 'login',
        logout: 'logout',
        changePassword: 'changePassword',
        resetMyPassword: 'resetMyPassword', // 找回密码
    },
    dictionaryCode: {
        import: 'dictionaryCodeImport',
    },
    user: {
        resetPassword: 'resetPassword', // 重置用户密码
    },
    systemSetting: {
        getSettingByType: 'getSettingByType', // 根据配置类别获取配置信息
        saveByType: 'saveByType', // 根据配置类别保存配置信息
    },
    dataYear: {
        setYxxq: 'setYxxq', // 设置院系校区
        reCreateProvinceData: 'reCreateProvinceData', // 重新生成指定年份的省份数据
    },
    provincePolicy: {
        getSourceLine: 'getSourceLine', // 获取分数线
        saveSourceLine: 'saveSourceLine', // 保存分数线
        getSavedDbfs: 'getSavedDbfs', // 获取保存到服务器的DBF文件
        saveSavedDbfs: 'saveSavedDbfs', // 保存DBF到服务器
        markProvinceType: 'provinceType', // 设置省份类别
        toggleCanMergeDbf: 'toggleCanMergeDbf', // 翻转是否可以合并DBF
    },
    statistics: {
        sourceLine: 'sourceLine',
        fs: 'statisticsFS', // 分数统计
        pw: 'statisticsPW', // 排位统计
        pwExport: 'statisticsPWExport', // 排位导出
        zymzl: 'statisticsZYMZL', // 志愿满足率
        zyrd: 'statisticsZYRD', // 专业热度
        groupByNfLqlbSf: 'statisticsGroupByNfLqlbSf', // 按照年份、省份、录取类别分组
        common: 'statisticsCommon', // 通用统计
    },
    student: {
        importExcel: 'importStudentExcel',
        exportExcel: 'exportStudentExcel',
        importPhotos: 'importStudentPhotos',
    },
    yxxq: {
        import: 'importYxxq',
    },
    sourceLineCollect: {
        export: 'exportSourceLineCollect',
        import: 'importSourceLineCollect',
    },
    studentExportLog: {
        export: 'exportStudentExportLog',
        exportConfig: 'exportConfig',
    },
    zsjh: {
        import: 'zsjhImport',
        export: 'zsjhExport',
    },
    wcxx: {
        import: 'wcxxImport',
        export: 'wcxxExport',
    },
    tdfs: {
        import: 'tdfsImport',
        export: 'tdfsExport',
    },
    xdgx: {
        import: 'xdgxImport',
        export: 'xdgxExport',
    },
};
