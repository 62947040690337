<template>
    <div class="login">
        <div style="width: 100%; text-align: center">
            <img class="head-icon" src="../assets/main-logo1.png" style="height: 80px; width: 200px;" alt="logo"/>
        </div>

        <div style="width: 100%; text-align: center">
            <strong style="margin-left: 5px; font-size: 30px; color: #044096;">{{ company.title }}</strong>
        </div>

        <div class="login-panel">
            <el-row>
                <el-col :span="6" :offset="9">
                    <div class="panel panel-default">
                        <div class="panel-heading" style="background-color: #044096; text-align: center;">
                            <strong style="color: #ffffff;">用户登录</strong>
                        </div>
                        <div class="panel-body" @keyup.enter="handleSubmit" v-if="!isLoadingFormData">
                            <el-alert
                                    :title="errorMessage"
                                    :closable="false"
                                    type="error"
                                    show-icon
                                    style="margin-bottom: 10px; padding-left: 6px;"
                                    v-show="checkFailed"
                            />

                            <el-form ref="form" :model="form" :rules="rules">

                                <el-form-item prop="accountName">
                                    <el-input placeholder="用户名" v-model.trim="form.accountName" autofocus>
                                        <i slot="prefix" class="fa fa-user fa-fw"></i>
                                    </el-input>
                                </el-form-item>

                                <el-form-item prop="password" ref="passwordFormItem">
                                    <el-input
                                            ref="passwordInput"
                                            placeholder="密码"
                                            type="password"
                                            v-model.trim="form.password"
                                    >
                                        <i slot="prefix" class="fa fa-key fa-fw"></i>
                                    </el-input>
                                </el-form-item>

                                <el-form-item prop="code">
                                    <el-input v-model="form.code" placeholder="验证码" style="width: 200px;"/>

                                    <div style="position: absolute; top: -9px; left: 210px;">
                                        <img :src="validImgUrl" @click="handleClickValidImage" title="点击刷新" style="padding-top: 10px;"/>

                                        <el-button type="text" href="#" style="margin-left: 15px; font-size: 14px;" @click="handleResetMyPassword">
                                            忘记密码
                                        </el-button>
                                    </div>
                                </el-form-item>

                                <el-form-item>
                                    <el-button
                                            type="primary"
                                            plain
                                            style="width: 100%; font-size: 18px;"
                                            @click="handleSubmit"
                                            :disabled="isChecking">
                                        <i class="fa fa-sign-in fa-fw"></i>
                                        <span v-if="!isChecking">登 录</span>
                                        <span v-else>登录中...</span>
                                    </el-button>
                                </el-form-item>

                            </el-form>
                        </div>
                        <div class="panel-footer">
                            <p>
                                <i class="fa fa-home fa-fw"></i>
                                <a :href="company.url" target="_blank" style="margin-left: 10px;">{{ company.url }}</a>
                            </p>
                            <p>
                                <i class="fa fa-tty fa-fw"></i>
                                <span style="margin-left: 10px;">{{ company.tel }}</span>
                            </p>
                            <p>
                                <i class="fa fa-copyright fa-fw"></i>
                                <span style="margin-left: 10px;">{{ company.copyright }}</span>
                            </p>
                            <p>
                                <i class="fa fa-code-fork fa-fw"></i>
                                <span style="margin-left: 10px;">{{ company.version }}(Client: {{ company.clientVersion }})</span>
                            </p>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <download-browser v-if="DownloadBrowserDialog.show" @close="DownloadBrowserDialog.handleClose"/>

        <rest-my-password v-if="RestMyPasswordDialog.show" @close="RestMyPasswordDialog.handleClose"/>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex';
    import {formPageMixin} from '@/roadhog/formPageMixin';
    import * as validators from '@/roadhog/validators';
    import {actionTypes} from '@/store/actionTypes';
    import DownloadBrowser from "@/components/DownloadBrowser";
    import RestMyPassword from "@/components/RestMyPassword";

    export default {
        components: {DownloadBrowser, RestMyPassword},
        mixins: [formPageMixin(null)],
        created() {
            document.title = this.company.title;
            this.pageTitle = this.company.title;
        },
        data() {
            return {
                pageTitle: null,
                isChecking: false,
                checkFailed: false,
                validImgUrl: '/api/code_image',
                errorMessage: '',
                DownloadBrowserDialog: {
                    show: false,
                    handleClose: () => {
                        this.DownloadBrowserDialog.show = false;
                        this.handleClickValidImage();
                    },
                    handleEdit: async () => {
                        this.DownloadBrowserDialog.show = true;
                    },
                },
                RestMyPasswordDialog: {
                    show: false,
                    handleClose: () => {
                        this.RestMyPasswordDialog.show = false;
                    },
                    handleAdd: () => {
                        this.RestMyPasswordDialog.show = true;
                    },
                },
            };
        },
        computed: {
            ...mapState({
                company: (state) => {
                    return state.global.webSettings;
                },
            }),
        },
        methods: {
            ...mapActions([actionTypes.auth.login, actionTypes.auth.getCurrentUser]),
            initForm() {
                return {
                    accountName: {
                        rules: [validators.required({message: '请输入用户名'})],
                    },
                    password: {
                        rules: [validators.required({message: '请输入密码'})],
                    },
                    code: {
                        rules: [validators.required({message: '请填写验证码'})],
                    },
                };
            },
            async commit(values) {
                // 用户名或密码错误
                console.log(values);
                this.checkFailed = false;
                const result = await this.login(values);
                if (result.success) {
                    await this.getCurrentUser();
                    this.$router.push('/');
                } else {
                    this.errorMessage = result.message;
                    this.handleClickValidImage();
                    this.checkFailed = true;
                }
            },
            handleClickValidImage() {
                this.validImgUrl = `/api/code_image?time=${new Date().getMilliseconds()}`;
            },
            handleResetMyPassword() {
                this.RestMyPasswordDialog.handleAdd();
            },
        },
    };
</script>

<style lang="scss">
    body {
        background-image: none;
    }

    .login {
        height: 100%;
        background-image: url("../assets/login-page-background.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .large-alert {
            .el-alert__content {
                padding: 15px 0;

                .el-alert__title {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }

        .login-panel {
            position: relative;
            margin-top: 10px;
            opacity: 0.8;

            .panel-heading {
                position: relative;
                /*text-indent: 45px;*/
                height: 32px;
                line-height: 32px;
                font-size: 1.3em;

                img {
                    position: absolute;
                    margin-top: 2px;
                    margin-left: -40px;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
</style>
