import {confirmDialog} from "@/roadhog/alert";

export const dialogMixin = (dialogRef = 'lanDialog') => {
    return {
        methods: {
            committed(args) {
                this.closeDialog(args);
            },
            handleClose() {
                this.$emit('close');
            },
            closeDialog(returnValue) {
                this.$refs[dialogRef].closeDialog();
                this.$emit('close', returnValue);
            },
            confirmCloseDialog(returnValue) {
                confirmDialog({
                    message: '关闭窗口',
                    ok: () => this.closeDialog(returnValue),
                });
            },
        },
    };
};
