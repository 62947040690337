import Vue from 'vue';
import Vuex from 'vuex';
import global from '@/store/modules/global';
import dataYear from "@/store/modules/dataYear";
import dictionaryCode from "@/store/modules/dictionaryCode";
import systemSetting from '@/store/modules/systemSetting';
import provincePolicy from "@/store/modules/provincePolicy";
import yxxq from "@/store/modules/yxxq";
import userManager from "@/store/modules/userManager";
import groupManager from "@/store/modules/groupManager";
import student from "@/store/modules/student";
import statistics from "@/store/modules/statistics";
import auth from "@/store/auth";
import sourceLineCollect from "@/store/modules/sourceLineCollect";
import studentExportLog from "@/store/modules/studentExportLog";
import zsjh from "@/store/modules/zsjh";
import wcxx from "@/store/modules/wcxx";
import tdfs from "@/store/modules/tdfs";
import xdgx from "@/store/modules/xdgx";
import ems from "@/store/modules/ems";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        global,
        auth,
        dataYear,
        dictionaryCode,
        systemSetting,
        provincePolicy,
        yxxq,
        userManager,
        groupManager,
        student,
        statistics,
        sourceLineCollect,
        studentExportLog,
        zsjh,
        wcxx,
        tdfs,
        xdgx,
        ems,
    },
    strict: process.env.NODE_ENV !== 'production',
});
