import {MessageBox} from 'element-ui';

export const confirmDialog = ({message = '确定要删除项目', itemName = null, ok, cancel}) => {
    return MessageBox.confirm(`${message} ${itemName ? `'${itemName}'` : ''} ?`, '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    }).then(() => {
        if (_.isFunction(ok)) {
            ok();
        }
    }).catch(() => {
        if (_.isFunction(cancel)) {
            cancel();
        }
    });
};

export const alertError = (message) => {
    return MessageBox.alert(message, {
        title: '错误',
        confirmButtonText: '确定',
        type: 'error',
        closeOnPressEscape: true,
    });
};

export const alertMessage = (message) => {
    return MessageBox.alert(message, {
        title: '消息',
        confirmButtonText: '确定',
        type: 'success',
    });
};
