import {generateCurdManager} from "@/store/baseManager";
import {actionTypes} from "@/store/actionTypes";
import axios from "axios";

export const baseUrl = '/ems';

const needColumns = ['ksh', 'ems'];

const store = generateCurdManager({
    namespaced: true,
    url: baseUrl,
    listProperty: 'emsList',
    serverPagination: true,
});

const {actions} = store;

actions[actionTypes.common.import] = async ({dispatch}, {nf, file}) => {
    const {columns, recorders} = await dispatch(actionTypes.common.parseExcel, file, {root: true});
    if (recorders.length === 0) {
        throw new Error('没有数据可以导入');
    }

    const difference = _.difference(needColumns, columns);
    if (difference.length > 0) {
        throw new Error('缺少列：' + difference);
    }

    const parsedRecorders = _.map(recorders, n => {
        return {
            ksh: n.ksh,
            ems: n.ems,
        };
    });
    await axios.post(`${baseUrl}/import/${nf}`, parsedRecorders, {formPost: true});
};

actions[actionTypes.common.export] = async ({dispatch}, pagination) => {
    const {data} = await axios.post(`${baseUrl}/export`, pagination);
    dispatch(actionTypes.common.openDownloadLink, data, {root: true});
};

export default store;
