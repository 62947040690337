import axios from 'axios';
import {mutationTypes} from "@/store/mutationTypes";
import {actionTypes} from "@/store/actionTypes";

const baseUrl = '/zsjh';

const state = {
    zsjhList: [],
    columns: [],
};

const mutations = {
    [mutationTypes.common.setListData](state, data) {
        if (data == null) {
            return;
        }
        state.zsjhList = data.recorders;
        state.columns = data.columns;
    },
};

const actions = {
    async [actionTypes.common.list]({commit}, {nf, sf}) {
        const {data} = await axios.get(`${baseUrl}/${nf}/${sf}`);
        commit(mutationTypes.common.setListData, data);
    },
    async [actionTypes.zsjh.import]({dispatch}, file) {
        const {recorders, columns} = await dispatch(actionTypes.common.parseExcel, file, {root: true});
        const nfs = _.keys(_.groupBy(recorders, '年份'));

        if (nfs.length !== 1) {
            throw new Error('有超过一个年份: ' + nfs);
        }
        const data = {
            nf: nfs[0],
            data: JSON.stringify({columns, recorders}),
        };
        await axios.post(`${baseUrl}/save`, data, {formPost: true});
    },
    async [actionTypes.zsjh.export]({dispatch}, nf) {
        const {data} = await axios.get(`${baseUrl}/export/${nf}`);
        dispatch(actionTypes.common.openDownloadLink, data, {root: true});
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
