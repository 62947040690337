import axios from 'axios';
import {actionTypes} from "@/store/actionTypes";
import {mutationTypes} from "@/store/mutationTypes";
import {alertMessage} from "@/roadhog/alert";

const state = {
    currentUser: null,
};

const mutations = {
    [mutationTypes.auth.setCurrentUser](state, user) {
        state.currentUser = user;
    },
    [mutationTypes.auth.clearCurrentUser](state) {
        state.currentUser = null;
    },
};

const actions = {
    async [actionTypes.auth.getCurrentUser]({commit}) {
        const {data} = await axios.get('/current_user');
        commit(mutationTypes.auth.setCurrentUser, data);
    },
    async [actionTypes.auth.login](store, {accountName, password, code}) {
        const response = await axios.post('/login', {accountName, password, code});
        return response.data;
    },
    async [actionTypes.auth.logout]({commit}) {
        await axios.get('/logout');
        commit(mutationTypes.auth.clearCurrentUser);
    },
    async [actionTypes.auth.changePassword]({dispatch}, {oldPassword, newPassword}) {
        await axios.put('/change_password', {oldPassword, newPassword}, {formPost: true});
        await alertMessage('密码修改成功，请重新登录');
        dispatch(actionTypes.auth.logout);
    },
    async [actionTypes.auth.resetMyPassword](store, {email, code}) {
        await axios.put('/reset_my_password', {email, code}, {formPost: true});
    },
};

export default {
    state,
    mutations,
    actions,
};
