<template>
    <div id="app">
        <public-frame v-if="isPublicPage"/>
        <template v-else>
            <auth-frame v-if="currentUser"/>
            <login v-else/>
        </template>
    </div>
</template>

<script>
    import Login from "@/components/Login";
    import AuthFrame from "@/components/frame/AuthFrame";
    import PublicFrame from '@/components/frame/PublicFrame';

    export default {
        name: 'app',
        components: {AuthFrame, Login, PublicFrame},
        watch: {
            $route: {
                handler(newValue) {
                    // 防止每次刷新都会闪现一下登录页
                    if (newValue.fullPath === '/') {
                        return;
                    }
                    this.isPublicPage = newValue.meta.public;
                },
                immediate: true,
            },
        },
        data() {
            return {
                isPublicPage: true,
            };
        },
    };
</script>
