import {generateCurdManager} from "@/store/baseManager";
import {actionTypes} from "@/store/actionTypes";
import axios from "axios";
import {getCode, getCodeByCodeTypeAndName, getCodesByType} from "@/store/modules/dictionaryCode";
import {dictionaryCodeType} from "@/store/enum";
import {mutationTypes} from "@/store/mutationTypes";

export const baseUrl = '/xdgx';

export const needColumns = {
    nf: '年份',
    gxId: '高校名称',
    sfId: '省份',
    bz: '备注',
    klId: '科类',
    fs: '录取分数',
};

const store = generateCurdManager({
    namespaced: true,
    url: baseUrl,
    listProperty: 'xdgxList',
});

const {actions} = store;

actions[actionTypes.common.list] = async ({commit, rootState}, nf) => {
    if (!nf) {
        return;
    }
    const {data} = await axios.get(`${baseUrl}/list/${nf}`);
    const sfList = getCodesByType(rootState, dictionaryCodeType.Province).map(i => i.id);
    const xdgxList = getCodesByType(rootState, dictionaryCodeType.XDGX).map(i => i.id);
    commit(mutationTypes.common.setListData, _.orderBy(data, [
        i => sfList.indexOf(i.sfId),
        i => xdgxList.indexOf(i.gxId),
        'klId',
    ], [
        'asc',
        'asc',
        'asc',
    ]));
};

actions[actionTypes.xdgx.import] = async ({dispatch, rootState}, file) => {
    const {columns, recorders} = await dispatch(actionTypes.common.parseExcel, file, {root: true});

    const difference = _.difference(_.values(needColumns), columns);
    if (difference.length > 0) {
        throw new Error('缺少必须的列：' + difference);
    }

    if (recorders.length === 0) {
        throw new Error('没有记录可以导入');
    }

    const parsedRecorders = _.map(recorders, (n) => {
        const r = {};
        _.forEach(_.keys(needColumns), k => {
            let value = n[needColumns[k]];

            if (k === 'gxId') {
                value = getCodeByCodeTypeAndName(rootState, dictionaryCodeType.XDGX, value, true).id;
            } else if (k === 'klId') {
                value = getCodeByCodeTypeAndName(rootState, dictionaryCodeType.KL, value, true).id;
            } else if (k === 'sfId') {
                value = getCodeByCodeTypeAndName(rootState, dictionaryCodeType.Province, value, true).id;
            }

            r[k] = value;
        });
        return r
    });

    const groupByNf = _.groupBy(parsedRecorders, n => n.nf);
    if (_.keys(groupByNf).length !== 1) {
        throw new Error(`有超过一个年份的数据：${_.keys(groupByNf)}`);
    }

    await axios.post(`${baseUrl}/import`, parsedRecorders, {formPost: true});
};

actions[actionTypes.xdgx.export] = async ({state, dispatch, rootState}, nf) => {
    const q = {
        sheetName: `${nf}年兄弟高校录取分数`,
        columnKeys: _.keys(needColumns),
        columns: null,
        recorders: null,
    };
    q.columns = _.map(q.columnKeys, k => needColumns[k]);
    q.recorders = state.xdgxList.map(n => {
        const item = {};
        _.forEach(q.columnKeys, k => {
            if (_.endsWith(k, "Id")) {
                item[k] = getCode(rootState, n[k]).name;
            } else {
                item[k] = n[k];
            }
        });
        return item;
    });

    const {data} = await axios.post(`${baseUrl}/export`, q);
    dispatch(actionTypes.common.openDownloadLink, data, {root: true});
};

export default store;
