<template>
    <el-container style="height: 100%; width: 100%;">
        <el-header style="text-align: center; background-color: #ffffff">
            <div style="width: 100%; height: 10px; background-color: darkblue; position: absolute; left: 0; right: 0"></div>
            <div style="width: 100%; margin-top: 20px;">
                <img src="../../assets/zs_logo.jpg" alt="厦门大学招生网" />
            </div>
        </el-header>

        <el-container class="main-frame" style="margin-top: 35px;">
            <router-view />
        </el-container>
    </el-container>
</template>

<script>
    export default {
        mounted () {
            this.$store.commit('calcViewHeight');
        },
    };
</script>
