import axios from 'axios';
import {generateCurdManager} from "@/store/baseManager";
import {actionTypes} from "@/store/actionTypes";

const url = '/system_setting';

const store = generateCurdManager({
    namespaced: true,
    url,
    listProperty: 'settings',
    serverPagination: false,
});

const {actions} = store;

Object.assign(actions, {
    async [actionTypes.systemSetting.getSettingByType](store, type) {
        const {data} = await axios.get(`${url}/${type}`);
        return data;
    },
    async [actionTypes.systemSetting.saveByType](sotre, {type, config}) {
        await axios.put(`${url}/${type}`, config);
    },
});

export default store;
