import axios from 'axios';
import {generateCurdManager} from "@/store/baseManager";
import {actionTypes} from "@/store/actionTypes";

const store = generateCurdManager({
    namespaced: true,
    url: '/user_manager',
    listProperty: 'users',
});

const {actions} = store;

Object.assign(actions, {
    async [actionTypes.user.resetPassword](store, accountName) {
        const {data} = await axios.put(`/user_manager/reset_password/${accountName}`, {formPost: true});
        return data.message;
    },
});

export default store;
