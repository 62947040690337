import axios from 'axios';
import {generateCurdManager} from "@/store/baseManager";
import {actionTypes} from "@/store/actionTypes";
import {mutationTypes} from "@/store/mutationTypes";

const store = generateCurdManager({
    namespaced: true,
    url: '/yxxq',
    listProperty: 'yxxqs',
});

const {actions} = store;

actions[actionTypes.yxxq.import] = async (store, data) => {
    await axios.post('/yxxq/import', data);
};

const getCodeName = (id, codes) => {
    const code = codes.find(n => n.id === id);
    return code ? code.name : null;
};

actions[actionTypes.common.list] = async ({commit, rootState}) => {
    const {data} = await axios.get('/yxxq');
    const codes = rootState.dictionaryCode.codes;
    _.forEach(data, d => {
        d.zymc = getCodeName(d.zyId, codes);
        d.yxmc = getCodeName(d.yxId, codes);
        d.xqmc = getCodeName(d.xqId, codes);
        d.xkdlmc = getCodeName(d.xkdlId, codes);
        d.xbmc = getCodeName(d.xbId, codes);
    });
    commit(mutationTypes.common.setListData, data);
};

export default store;
