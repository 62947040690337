import Vue from 'vue';
import axios from 'axios';
import {generateCurdManager} from "@/store/baseManager";
import {getterTypes} from "@/store/getterTypes";
import {mutationTypes} from "@/store/mutationTypes";
import {actionTypes} from "@/store/actionTypes";
import {dictionaryCodeTypeName} from "@/store/enum";

const store = generateCurdManager({
    namespaced: true,
    url: '/dictionary_code',
    listProperty: 'codes',
});

const {getters, mutations, actions} = store;

mutations[mutationTypes.common.setListData] = (state, data) => {
    state.codes = data;

    // 对于所有没有父节点的，都当作是一个新的根节点来处理
    const codeTypes = _.chain(state.codes).filter((n) => n.parentId == null).map('dcType').value();
    console.log('codeTypes: ', codeTypes);

    _.forEach(codeTypes, (t) => {
        console.log('process tree: ' + t);
        const root = _.find(state.codes, (n) => n.dcType === t && n.parentId == null);
        root.level = 0;
        root.path = root.id;
        root.namePath = root.name;
        processPathAndLevel(state.codes, root);
        Vue.set(state, _.camelCase(t), _.filter(state.codes, (n) => n.dcType === t && n.parentId != null));
    });
};

Object.assign(getters, {
    [getterTypes.dictionaryCode.getByType](state) {
        return (codeType) => state.codes.filter(n => n.dcType === codeType);
    },
    [getterTypes.dictionaryCode.getTreeData](state) {
        const result = [];
        processTreeData(state.codes, null, result);
        return result;
    },
});

Object.assign(actions, {
    async [actionTypes.dictionaryCode.import]({dispatch}, {parentId, formData}) {
        const {data} = await axios.post(`/dictionary_code/import/${parentId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });

        if (data.success) {
            dispatch(actionTypes.common.list);
        }
        return data;
    },
});

const processPathAndLevel = (allItems, parentNode) => {
    const children = _.chain(allItems).filter((n) => n.dcType === parentNode.dcType && n.parentId === parentNode.id).sortBy('orderNumber').value();
    if (children.length > 0) {
        parentNode.children = children;
        _.forEach(children, (n) => {
            n.level = parentNode.level + 1;
            n.path = `${parentNode.path}/${n.id}`;
            n.namePath = `${parentNode.namePath}/${n.name}`;
            processPathAndLevel(allItems, n);
        });
    }
};

const mapTreeNode = (n) => {
    return {
        id: n.id,
        name: n.name,
        code: n.code,
        dcType: n.dcType,
        editable: n.editable,
        field1: n.field1,
        field2: n.field2,
        field3: n.field3,
        field4: n.field4,
        field5: n.field5,
        orderNumber: n.orderNumber,
    };
};

const processTreeData = (items, root, result) => {
    if (root == null) {
        const rootNodes = _.chain(items).filter((n) => n.parentId == null).map(mapTreeNode).value();
        _.forEach(rootNodes, (n) => {
            result.push(n);
            processTreeData(items, n, result);
        });
    } else {
        const children = _.chain(items).filter((n) => n.parentId === root.id).map(mapTreeNode).value();
        if (children.length > 0) {
            root.children = children;
            _.forEach(children, (n) => {
                processTreeData(items, n, result);
            });
        }
    }
};

export const getCodesByType = (rootState, codeType) => {
    return _.chain(rootState.dictionaryCode.codes).filter(i => i.dcType === codeType).orderBy('orderNumber').value();
};

export const getCode = (rootState, id) => {
    return rootState.dictionaryCode.codes.find(n => n.id === id);
};

export const getCodeByCodeTypeAndName = (rootState, codeType, name, throwError) => {
    const result = rootState.dictionaryCode.codes.find(n => n.dcType === codeType && n.name === name);
    if (result == null && throwError) {
        throw new Error(`无法解析 ${dictionaryCodeTypeName[codeType]} : ${name}`);
    }
    return result;
};

export default store;
