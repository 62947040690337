import axios from 'axios';
import {actionTypes} from "@/store/actionTypes";
import {mutationTypes} from "@/store/mutationTypes";
import {getterTypes} from "@/store/getterTypes";
import Vue from "vue";

const url = '/province_policy';

const actions = {
    [actionTypes.common.list]: async ({rootGetters}, dataYearId) => {
        const response = await axios.get(`${url}/get_by_data_year/${dataYearId}`);
        _.forEach(response.data, n => {
            const province = rootGetters [`dictionaryCode/${getterTypes.common.getById}`](n.provinceId);
            n.provinceName = province.name;
            n.provinceCode = province.code;
            n.provinceOrderNumber = province.orderNumber;
        });

        return response.data;
    },
    async [actionTypes.common.get](store, id) {
        const {data} = await axios.get(`${url}/${id}`);
        return data;
    },
    [actionTypes.provincePolicy.getSourceLine]: async ({commit}, provincePolicyId) => {
        const response = await axios.get(`/province_policy/${provincePolicyId}/source_line`);
        commit(mutationTypes.provincePolicy.setSourceLine, {provincePolicyId, sourceLineList: response.data});
    },
    [actionTypes.provincePolicy.saveSourceLine]: async ({dispatch}, {provincePolicyId, sourceLines}) => {
        await axios.put(`/province_policy/${provincePolicyId}/source_line`, sourceLines);
        dispatch(actionTypes.provincePolicy.getSourceLine, provincePolicyId);
    },
    [actionTypes.provincePolicy.getSavedDbfs]: async (store, provincePolicyId) => {
        const {data} = await axios.get(`${url}/${provincePolicyId}/get_saved_dbfs`);
        return data;
    },
    [actionTypes.provincePolicy.saveSavedDbfs]: async (store, {provincePolicyId, dbfs}) => {
        const {data} = await axios.put(`${url}/${provincePolicyId}/update_saved_dbfs`, dbfs);
        return data;
    },
    [actionTypes.provincePolicy.markProvinceType]: async ({dispatch}, {provincePolicyId, value}) => {
        await axios.put(`${url}/${provincePolicyId}/mark_province_type`, {provinceType: value});
        dispatch(actionTypes.common.list);
    },
    [actionTypes.provincePolicy.toggleCanMergeDbf]: async (store, id) => {
        await axios.put(`${url}/toggle_can_merge_dbf/${id}`);
    },
};

const mutations = {
    [mutationTypes.provincePolicy.setSourceLine]: (state, {provincePolicyId, sourceLineList}) => {
        const policy = state.provincePolicies.find(n => n.id === provincePolicyId);
        Vue.set(policy, 'sourceLine', sourceLineList);
    }
};

export default {
    namespaced: true,
    actions,
    mutations,
};
