<template>
    <lan-form-dialog
            ref="lanDialog"
            title="修改密码"
            @submit="handleSubmit"
            @close="handleClose"
            :is-loading="isLoadingFormData"
    >

        <el-form v-if="!isLoadingFormData"
                 ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="100px"
                 size="mini">

            <el-form-item prop="oldPassword" label="原密码">
                <el-input type="password" v-model="form.oldPassword"/>
            </el-form-item>

            <el-form-item prop="newPassword" label="新密码">
                <el-input type="password" v-model="form.newPassword"/>
            </el-form-item>

            <el-form-item prop="confirmPassword" label="确认新密码">
                <el-input type="password" v-model="form.confirmPassword"/>
            </el-form-item>

        </el-form>

    </lan-form-dialog>
</template>

<script>
    import {formPageMixin} from '@/roadhog/formPageMixin';
    import {dialogMixin} from '@/roadhog/dialogMixin';
    import * as validators from "@/roadhog/validators";
    import {actionTypes} from "@/store/actionTypes";

    export default {
        mixins: [formPageMixin(null), dialogMixin()],
        data() {
            return {};
        },
        methods: {
            initForm() {
                return {
                    oldPassword: {rules: [validators.required()]},
                    newPassword: {rules: [validators.required()]},
                    confirmPassword: {rules: [validators.required(), validators.equal_to(this, {field: 'newPassword', message: '两次密码不一致'})]},
                };
            },
            processValues(values) {
                return values;
            },
            commit(values) {
                this.$store.dispatch(actionTypes.auth.changePassword, values);
            },
        },
    };
</script>
