import axios from 'axios';
import {generateCurdManager} from "@/store/baseManager";
import {actionTypes} from "@/store/actionTypes";
import {alertMessage} from "@/roadhog/alert";

const store = generateCurdManager({
    namespaced: true,
    url: '/student',
    listProperty: 'students',
    serverPagination: true,
});

const {actions} = store;

Object.assign(actions, {
    async [actionTypes.student.importExcel](store, excelData) {
        const {data} = await axios.post('/data_import/import_big_view', excelData);
        await alertMessage(`导入总数： ${data.totalNumbers}; \n 新增：${data.insertNumbers} \n 更新：${data.updateNumbers}`);
    },
    async [actionTypes.student.exportExcel]({dispatch}, {sheetName, columns, columnKeys, pagination}) {
        pagination.sheetName = sheetName;
        pagination.columns = columns;
        pagination.columnKeys = columnKeys;
        const {data} = await axios.post('/student/export', pagination);
        dispatch(actionTypes.common.openDownloadLink, data, {root: true});
    },
    async [actionTypes.student.importPhotos](store, photos) {
        const formData = new FormData();
        _.forEach(photos, (f) => {
            formData.append('photos', f);
        });
        await axios.post('/data_import/import_photos', formData, {formPost: true});
    },
});

export default store;
