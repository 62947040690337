import axios from 'axios';
import {generateCurdManager} from "@/store/baseManager";
import {mutationTypes} from "@/store/mutationTypes";
import {actionTypes} from "@/store/actionTypes";
import {alertMessage} from "@/roadhog/alert";
import {getCode} from "@/store/modules/dictionaryCode";

const store = generateCurdManager({
    namespaced: true,
    url: '/data_year',
    listProperty: 'dataYears',
});

const {mutations, actions} = store;

mutations[mutationTypes.common.setListData] = (state, data) => {
    _.forEach(data, n => {
        n.hasYXXQ = n.yxxq != null && n.yxxq !== '';
        return true;
    });
    state.dataYears = data;
};

actions[actionTypes.dataYear.setYxxq] = async ({dispatch}, {id, yxxqJson}) => {
    const url = `/data_year/${id}/yxxq`;
    await axios.put(url, yxxqJson);
    dispatch(actionTypes.common.list);
};

actions[actionTypes.dataYear.reCreateProvinceData] = async ({dispatch, rootState}, id) => {
    const {data} = await axios.put(`/data_year/recreate_province_data/${id}`);
    const result = JSON.parse(data.message);
    if (result.length === 0) {
        alertMessage('没有找到差异记录');
    } else {
        const provinceNames = _.map(result, n => getCode(rootState, n.provinceId).name);
        alertMessage(`新增省份记录：${provinceNames.join(',')}`);
        dispatch(actionTypes.common.list);
    }
};

export default store;
