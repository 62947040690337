export const dictionaryCodeType = {
    Province: 'Province',
    EnterType: 'EnterType',
    XQ: 'XQ',
    YX: 'YX',
    ZY: 'ZY',
    KL: 'KL',
    XKDL: 'XKDL',
    XB: 'XB',
    CJX: 'CJX',
    MZ: 'MZ',
    XDGX: 'XDGX',
    UserCustom: 'UserCustom',
};

export const dictionaryCodeTypeName = {
    Province: '省份',
    EnterType: '录取类别',
    KL: '科类',
    XDGX: '兄弟高校',
};


export const roles = {
    admin: 'admin',
    leader: 'leader',
};

export const studentExportType = {
    YHK: 'YHK',
    TZS: 'TZS',
};
