<template>
    <lan-form-dialog
            ref="lanDialog"
            title="找回密码"
            @submit="handleSubmit"
            @close="handleClose"
            :is-loading="false"
    >

        <el-form v-if="!isLoadingFormData"
                 ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="80px"
                 size="mini"
                 v-loading="isLoading"
        >

            <el-form-item prop="email" label="邮箱">
                <el-input v-model="form.email" placeholder="请输入您的邮箱"/>
            </el-form-item>

            <el-form-item prop="code" label="验证码">
                <el-input v-model="form.code" placeholder="验证码" style="width: 200px;"/>

                <div style="position: absolute; top: -11px; left: 210px;">
                    <img :src="validImgUrl" @click="handleClickValidImage" title="点击刷新" style="padding-top: 10px;"/>
                </div>
            </el-form-item>

        </el-form>

    </lan-form-dialog>
</template>

<script>
    import {formPageMixin} from '@/roadhog/formPageMixin';
    import {dialogMixin} from '@/roadhog/dialogMixin';
    import * as validators from "@/roadhog/validators";
    import {actionTypes} from "@/store/actionTypes";
    import {alertMessage} from "@/roadhog/alert";

    export default {
        mixins: [formPageMixin(null), dialogMixin()],
        data() {
            return {
                validImgUrl: '/api/code_image',
                isLoading: false,
            };
        },
        methods: {
            initForm() {
                return {
                    email: {rules: [validators.required(), validators.email()]},
                    code: {rules: [validators.required({message: '请填写验证码'})]},
                };
            },
            handleClickValidImage() {
                this.validImgUrl = `/api/code_image?time=${new Date().getMilliseconds()}`;
            },
            async commit(values) {
                this.isLoading = true;
                try {
                    await this.$store.dispatch(actionTypes.auth.resetMyPassword, values);
                    this.closeDialog();
                    alertMessage(`一封重置密码的邮件已经发送到您的邮箱：${values.email}，请您尽快查收`);
                } catch (e) {
                    this.handleClickValidImage();
                } finally {
                    this.isLoading = false;
                }
            },
        },
    };
</script>
