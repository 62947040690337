import {actionTypes} from "@/store/actionTypes";
import axios from "axios";
import {mutationTypes} from "@/store/mutationTypes";

const baseUrl = '/student_export_log';

const state = {
    logs: [],
};

const mutations = {
    [mutationTypes.common.setListData](state, data) {
        _.forEach(data, l => l.lqlbIds = _.split(l.lqlbIds, ";"));
        state.logs = data;
    },
};

const actions = {
    async [actionTypes.common.list]({commit}, query) {
        const {data} = await axios.post(`${baseUrl}/list`, query);
        commit(mutationTypes.common.setListData, data);
    },
    async [actionTypes.studentExportLog.export]({dispatch}, query) {
        const {data} = await axios.post(`${baseUrl}/export`, query);
        dispatch(actionTypes.common.openDownloadLink, data, {root: true});
    },
    async [actionTypes.studentExportLog.exportConfig](store, exportType) {
        const {data} = await axios.get(`${baseUrl}/export_config/${exportType}`);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
