import axios from 'axios';
import {actionTypes} from "@/store/actionTypes";
import {mutationTypes} from "@/store/mutationTypes";
import {dictionaryCodeType} from "@/store/enum";
import {getterTypes} from "@/store/getterTypes";


const baseUrl = '/province_source_line';

const state = {
    provinceSourceLines: [],
};

export const columns = {
    nf: "年份",
    sf: "省份",
    bz: '备注',
    zf: '总分',
    wk1: "（文科）一本线/自主招生线",
    lk1: "（理科）一本线/自主招生线",
    wk2: "（文科）二本线/高水平运动队线",
    lk2: "（理科）二本线/高水平运动队线",
    source1: "一本线/自主招生线",
    source2: "二本线/高水平运动队线",
};

const mutations = {
    [mutationTypes.common.setListData](state, data) {
        state.provinceSourceLines = data;
    },
};

const actions = {
    async [actionTypes.common.list]({commit, rootState}, year) {
        const {data} = await axios.get(`${baseUrl}/${year}`);
        const sfList = rootState.dictionaryCode.codes.filter(n => n.dcType === dictionaryCodeType.Province);
        _.forEach(data, d => {
            const sf = sfList.find(n => n.name === d.sf);
            d.sfOrderNumber = sf.orderNumber;
        });
        commit(mutationTypes.common.setListData, _.orderBy(data, ['sfOrderNumber']));
    },
    async [actionTypes.common.edit](store, {id, data}) {
        await axios.put(`${baseUrl}/edit/${id}`, data, {formPost: true});
    },
    async [actionTypes.sourceLineCollect.export]({dispatch}, recorders) {
        const columnKeys = Object.keys(columns);
        dispatch(actionTypes.common.exportExcel, {
            sheetName: '分数线',
            columns: columnKeys.map(i => columns[i]),
            columnKeys,
            recorders,
        }, {root: true});
    },
    async [actionTypes.sourceLineCollect.import]({rootState}, {excelColumns, recorders}) {
        // 检查年份列不能有空值
        if (recorders.length === 0) {
            return;
        }

        const columnKeys = Object.keys(columns);
        const columnValues = columnKeys.map(i => columns[i]);
        const difference = _.difference(columnValues, excelColumns);
        if (difference.length > 0) {
            throw new Error(`Excel文件中缺少必须的列：${difference}`);
        }

        const parsedRecorders = [];

        const sfList = rootState.dictionaryCode.codes.filter(n => n.dcType === dictionaryCodeType.Province);

        _.forEach(recorders, r => {
            const item = {};
            _.forOwn(r, (value, key) => {
                const index = columnValues.indexOf(key);
                item[columnKeys[index]] = value;
            });

            if (!item.nf || !_.isNumber(item.nf)) {
                throw new Error('没有指定有效的年份');
            }

            if (!sfList.find(n => n.name === item.sf)) {
                throw new Error('未知的省份: ' + item.sf);
            }

            if (!checkSource(item.zf)) {
                throw new Error(`${columns.zf} 无效: ${item.zf}`);
            }

            parsedRecorders.push(item);
        });

        await axios.post(`${baseUrl}/import`, parsedRecorders, {formPost: true});
    },
};

const getters = {
    [getterTypes.common.getById](state) {
        return (id) => state.provinceSourceLines.find((n) => n.id === id);
    },
};

const checkSource = (i) => i == null || _.isNumber(i);

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
