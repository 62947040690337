<template>
    <div>
        <img src="../../assets/main-logo.png" class="logo" alt="logo"/>

        <span class="site-title">{{ siteTitle }}</span>

        <img src="../../assets/pic1.png" class="pic1" alt="pic1"/>

        <div class="user-info" v-if="currentUser">
            <el-dropdown trigger="click" @command="handleCommand">
                <el-button>
                    <i class="fa fa-user" style="margin-right: 15px"></i>
                    {{ currentUser.name }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <!--<el-dropdown-item command="userInfo"><i class="fa fa-edit fa-fw"></i> 修改用户信息</el-dropdown-item>-->
                    <el-dropdown-item command="changePassword"><i class="fa fa-key fa-fw"></i> 修改密码</el-dropdown-item>
                    <el-dropdown-item command="logout"><i class="fa fa-sign-out fa-fw"></i> 登出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <change-password v-if="changePasswordEditorDialog.show" @close="changePasswordEditorDialog.handleClose"/>
        <!--<div>top: {{ $store.state.global.headerHeight }}</div>-->
        <!--<div>main: {{ $store.state.global.contentHeight }}</div>-->
        <!--<div style="color: red">BUILD_DATE: {{ BUILD_DATE }}</div>-->
    </div>
</template>

<script>
    import {actionTypes} from "@/store/actionTypes";
    import ChangePassword from "@/components/profile/ChangePassword";

    export default {
        components: {ChangePassword},
        data() {
            return {
                userInfoEditorDialog: {
                    show: false,
                    handleClose: () => {
                        this.userInfoEditorDialog.show = false;
                        this.userInfoEditorDialog.recorder = null;
                    },
                    handleEdit: async () => {
                        await this.$store.dispatch(actionTypes.globalManager.getCurrentUser);
                        this.userInfoEditorDialog.show = true;
                    },
                },
                changePasswordEditorDialog: {
                    show: false,
                    handleClose: () => {
                        this.changePasswordEditorDialog.show = false;
                    },
                    handleEdit: () => {
                        this.changePasswordEditorDialog.show = true;
                    },
                },
            };
        },
        computed: {
            siteTitle() {
                return this.$store.state.global.webSettings.title;
            }
        },
        methods: {
            async handleCommand(cmd) {
                switch (cmd) {
                    case 'logout':
                        this.$store.dispatch(actionTypes.auth.logout);
                        this.$router.push('/login');
                        break;
                    case 'userInfo':
                        this.userInfoEditorDialog.handleEdit();
                        break;
                    case 'changePassword':
                        this.changePasswordEditorDialog.handleEdit();
                        break;
                    default:
                        throw new Error('unsupported cmd: ' + cmd);
                }
            },
        },
    };
</script>
