<template>
    <div class="page404">
        <div style="position: fixed; bottom: 20%; left: 50%; transform: translate(-50%, -50%);">
            <el-button type="primary" size="large" @click.prevent.stop="handleGoToIndex">返回首页</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            handleGoToIndex() {
                this.$router.push('/');
            },
        },
    };
</script>

<style lang="scss">
    .page404 {
        height: 100%;
        width: 100%;
        background-image: url('../assets/404.jpg');
        background-size: 100% 100%;
    }
</style>
