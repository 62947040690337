export const getterTypes = {
    common: {
        getById: 'getById',
    },
    dataImport: {
        getCommonBigView: 'getCommonBigView',
    },
    dictionaryCode: {
        getByType: 'getByType',
        getTreeData: 'getTreeData',
    },
};
