import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import {actionTypes} from "@/store/actionTypes";
import {getterTypes} from "@/store/getterTypes";
import {roles} from "@/store/enum";
import Page404 from '@/components/404';

Vue.use(Router);

export const routers = [
    {
        path: '/',
        redirect: '/student',
    },
    {
        path: '/login',
        beforeEnter(to, from, next) {
            const currentUser = store.state.auth.currentUser;
            if (currentUser) {
                next('/');
            } else {
                next();
            }
        },
        // component: LoginPage,
    },
    {
        path: '/student',
        text: '学生信息管理',
        icon: 'fa fa-id-card',
        component: () => import(/* webpackChunkName: "student" */'@/components/student/Index'),
        meta: {auth: true},
    },
    {
        path: '/statistics',
        text: '数据统计',
        icon: 'fa fa-pie-chart',
        component: () => import(/* webpackChunkName: "statistics"*/'@/components/statistics/Index'),
        meta: {auth: true},
        children: [
            {
                path: 'common',
                component: () => import(/* webpackChunkName: "statistics"*/'@/components/statistics/Common'),
                text: '常规统计',
                meta: {auth: true, role: [roles.admin]},
            },
            {
                path: 'zyzymzl',
                component: () => import(/* webpackChunkName: "statistics"*/'@/components/statistics/ZYZYMZL'),
                text: '志愿满足率统计',
                meta: {auth: true},
            },
            {
                path: 'lqfs',
                component: () => import(/* webpackChunkName: "statistics"*/'@/components/statistics/LQFS'),
                text: '分数位次统计',
                meta: {auth: true},
            },
            {
                path: 'pw',
                component: () => import(/* webpackChunkName: "statistics"*/'@/components/statistics/PW'),
                text: '排位统计',
                meta: {auth: true, role: [roles.admin]},
            },
            {
                path: 'zyrd',
                component: () => import(/* webpackChunkName: "statistics"*/'@/components/statistics/ZYRD'),
                text: '专业热度统计',
                meta: {auth: true, role: [roles.admin]},
            },
            {
                path: 'query_config',
                component: () => import(/* webpackChunkName: "statistics"*/'@/components/statistics/QueryConfig'),
                text: '查询设置',
                meta: {auth: true, role: [roles.admin]},
            },
        ],
    },
    {
        path: '/data_year',
        text: '年份数据设置',
        icon: 'fa fa-calendar-check-o',
        component: () => import(/* webpackChunkName: "admin"*/'@/components/dataYear/Index'),
        meta: {auth: true, role: [roles.admin]},
    },
    {
        path: '/merge',
        text: '录取数据合并',
        icon: 'fa fa-list-ol',
        component: () => import(/* webpackChunkName: "dbfImport"*/'@/components/dataYear/Index'),
        props: {isMergeIndex: true},
        meta: {auth: true, role: [roles.admin]},
    },
    {
        path: '/merge/:dataYearId/province_list',
        component: () => import(/* webpackChunkName: "dbfImport"*/'@/components/province/Index'),
        props: true,
        display: false,
        meta: {auth: true, role: [roles.admin]},
    },
    {
        name: 'dataImport',
        path: '/merge/:dataYearId/province_list/:provinceId/data_import/:provincePolicyId',
        component: () => import(/* webpackChunkName: "dbfImport"*/'@/components/dbfMerge/Index'),
        props: true,
        display: false,
        meta: {auth: true, role: [roles.admin]},
        beforeEnter: async (to, from, next) => {
            const provinceId = to.params.provinceId;
            const dataYearId = to.params.dataYearId;
            const provincePolicyId = to.params.provincePolicyId;

            const province = store.getters[`dictionaryCode/${getterTypes.common.getById}`](provinceId);
            const [dataYear, yxxq, provincePolicy, savedDbfs] = await Promise.all([
                store.dispatch(`dataYear/${actionTypes.common.get}`, dataYearId),
                store.dispatch(`yxxq/${actionTypes.common.list}`),
                store.dispatch(`provincePolicy/${actionTypes.common.get}`, provincePolicyId),
                store.dispatch(`provincePolicy/${actionTypes.provincePolicy.getSavedDbfs}`, provincePolicyId),
            ]);

            Object.assign(to.params, {
                dataYear,
                yxxq,
                provincePolicy,
                savedDbfs,
                province,
            });
            next();
        },
    },
    {
        path: '/import',
        component: () => import(/* webpackChunkName: "bigView"*/'@/components/import/Index'),
        icon: 'fa fa-upload',
        text: '规范数据导入',
        meta: {auth: true, role: [roles.admin]},
    },
    {
        path: '/source_collect',
        component: () => import(/* webpackChunkName: "sourceCollect"*/'@/components/sourceLineCollect/Index'),
        text: '分数信息采集',
        icon: 'fa fa-database',
        redirect: '/source_collect/source_line',
        meta: {auth: true, role: [roles.admin]},
        children: [
            {
                path: 'source_line',
                component: () => import(/* webpackChunkName: "sourceCollect"*/'@/components/sourceLineCollect/SourceLineCollectIndex'),
                text: '各省批次线采集',
                meta: {auth: true, role: [roles.admin]},
            },
            {
                path: 'zsjh',
                component: () => import(/* webpackChunkName: "sourceCollect"*/'@/components/sourceLineCollect/ZsjhIndex'),
                text: '招生计划采集',
                meta: {auth: true, role: [roles.admin]},
            },
            {
                path: 'wcxx',
                component: () => import(/* webpackChunkName: "sourceCollect"*/'@/components/sourceLineCollect/WcxxIndex'),
                text: '位次信息采集',
                meta: {auth: true, role: [roles.admin]},
            },
            {
                path: 'tdfs',
                component: () => import(/* webpackChunkName: "sourceCollect"*/'@/components/sourceLineCollect/TdfsIndex'),
                text: '投档分数采集',
                meta: {auth: true, role: [roles.admin]},
            },
            {
                path: 'xdgx',
                component: () => import(/* webpackChunkName: "sourceCollect"*/'@/components/sourceLineCollect/XdgxIndex'),
                text: '兄弟高校录取分数采集',
                meta: {auth: true, role: [roles.admin]},
            },
        ],
    },
    {
        path: '/ems',
        component: () => import(/* webpackChunkName: "admin"*/'@/components/ems/Index'),
        text: '通知书快递管理',
        icon: 'fa fa-gift',
        meta: {auth: true, role: [roles.admin]},
    },
    {
        path: '/student_export_log',
        text: '银行卡和通知书导出',
        icon: 'fa fa-credit-card',
        component: () => import(/* webpackChunkName: "studentExportLog"*/'@/components/studentExportLog/Index'),
        meta: {auth: true, role: [roles.admin]},
    },
    {
        path: '/yxxq',
        icon: 'fa fa-graduation-cap',
        text: '院系校区管理',
        component: () => import(/* webpackChunkName: "admin"*/'@/components/yxxq/Index'),
        meta: {auth: true, role: [roles.admin]},
    },
    {
        path: '/user_manager',
        text: '用户管理',
        icon: 'fa fa-user',
        component: () => import(/* webpackChunkName: "admin"*/'@/components/userManager/Index'),
        meta: {auth: true, role: [roles.admin]},
    },
    {
        path: '/dictionary_code',
        component: () => import(/* webpackChunkName: "admin"*/'@/components/dictionaryCode/Index'),
        icon: 'fa fa-book',
        text: '代码管理',
        meta: {auth: true, role: [roles.admin]},
    },
    {
        path: '/audit',
        component: () => import(/* webpackChunkName: "admin"*/'@/components/audit/Index'),
        icon: 'fa fa-shield',
        text: '审计日志',
        meta: {auth: true, role: [roles.admin]},
    },
    {
        path: '/public',
        component: () => import(/* webpackChunkName: "public"*/'@/components/publicSite/Index'),
        beforeEnter(to, from, next) {
            to.meta.public = true;
            next();
        },
        children: [
            {
                path: 'student_query',
                component: () => import(/* webpackChunkName: "public"*/'@/components/publicSite/StudentQuery'),
                text: '录取查询',
                icon: 'fa fa-balance-scale',
                display: false,
            },
            {
                path: 'wnlq_query',
                component: () => import(/* webpackChunkName: "public"*/'@/components/publicSite/WnlqQuery'),
                text: '历年录取查询',
                icon: 'fa fa-balance-scale',
                display: false,
            },
            {
                path: 'zsjh_query',
                component: () => import(/* webpackChunkName: "public"*/'@/components/publicSite/ZsjhQuery'),
                text: '招生计划查询',
                icon: 'fa fa-balance-scale',
                display: false,
            },
            {
                path: 'new_zsjh_query/:nf/:sf?',
                name: 'new_zsjh_query',
                component: () => import(/* webpackChunkName: "public"*/'@/components/publicSite/ZsjhNewQuery'),
                text: '招生计划查询',
                icon: 'fa fa-balance-scale',
                props: true,
                display: false,
            },
        ],
    },
    {
        path: '*',
        component: Page404,
        meta: {public: true},
    },
];

const router = new Router({
    mode: 'history',
    routes: routers,
});

router.beforeEach(async (to, from, next) => {
    // 登录页面
    if (to.fullPath === '/login') {
        next();
        return;
    }

    // 可以匿名访问的页面
    if (!to.meta.auth) {
        next();
        return;
    }

    let currentUser = store.state.auth.currentUser;
    if (!currentUser) {
        try {
            await store.dispatch(actionTypes.auth.getCurrentUser);
            currentUser = store.state.auth.currentUser;
        } catch (e) {
            next('/login');
        }
    }

    if (currentUser == null) {
        next('/login');
        return;
    }

    const {role} = to.meta;
    if (role && !checkRole(currentUser, role)) {
        next('/');
        return;
    }

    next();
});

export const checkRole = (currentUser, needRoles) => {
    if (currentUser == null) {
        return false;
    }

    if (needRoles == null) {
        return true;
    }

    if (_.isString(needRoles)) {
        needRoles = [needRoles];
    }

    const result = _.intersection(currentUser.groups, needRoles).length > 0;
    console.log(`need role: ${needRoles}, ${result}`);
    return result;
};

export default router;
