<template>
    <lan-form-dialog
            ref="lanDialog"
            title="下载浏览器"
            @submit="handleSubmit"
            @close="handleClose"
            :is-loading="false"
    >

        <div>
            <strong>Windows XP</strong> 系统请使用 <i class="fa fa-firefox"></i>Firefox
            <a @click.stop.prevent="download('firefox32')" href="#">32位</a>&nbsp;&nbsp;
            <a @click.stop.prevent="download('firefox64')" href="#">64位</a>
        </div>

        <div style="margin-top: 20px;">
            <strong>Windows 7或以上</strong> 系统请选择 <i class="fa fa-chrome"></i>Chrome
            <a @click.stop.prevent="download('chrome64')" href="#">64位</a>
        </div>

        <div slot="buttons"></div>

    </lan-form-dialog>
</template>

<script>
    import {dialogMixin} from "@/roadhog/dialogMixin";

    const baseDownloadUrl = '/download/browsers';

    export default {
        mixins: [dialogMixin()],
        methods: {
            download(browser) {
                const url = `${baseDownloadUrl}/${browser}.exe`;
                window.open(url);
                return false;
            },
        },
    };
</script>
