import axios from 'axios';
import {Enum, EnumsSet} from "@/roadhog/enums";
import {actionTypes} from "@/store/actionTypes";
import {alertError} from "@/roadhog/alert";
import {mutationTypes} from "@/store/mutationTypes";

const state = {
    mainHeight: 0,
    contentHeight: 0,
    headerHeight: 60,
    enums: {},
    enumNames: {
        ProvinceType: 'ProvinceType',
    },
    webSettings: {
        clientVersion: JSON.parse(process.env.VUE_APP_CLIENT_VERSION),
    },
};

const provinceType = new EnumsSet(state.enumNames.ProvinceType, [
    new Enum('Normal', 1, '普通'),
    new Enum('ZJ2017', 2, '高考改革(3+3)'),
    new Enum('FJ2021', 3, '高考改革(3+1+2)'),
]);

state.enums[provinceType.name] = provinceType;

const mutations = {
    calcViewHeight(state) {
        const mainFrame = document.getElementsByClassName('main-frame')[0];
        state.mainHeight = mainFrame.clientHeight;
        state.contentHeight = state.mainHeight - 5 * 2;
    },
    [mutationTypes.global.setWebSettings](state, data) {
        document.title = data.title;
        state.webSettings = Object.assign(state.webSettings, data);
    },
};

const actions = {
    async [actionTypes.common.exportExcel]({dispatch}, {sheetName, columns, columnKeys, recorders}) {
        if (!sheetName) {
            sheetName = 'sheet1';
        }

        if (columns.length !== columnKeys.length) {
            alertError('导出的列和列名长度不匹配');
            return false;
        }

        if (recorders == null || recorders.length === 0) {
            alertError('没有数据要导出');
            return false;
        }

        const {data: downloadId} = await axios.post('/export_excel', {sheetName, columns, columnKeys, recorders});
        dispatch(actionTypes.common.openDownloadLink, downloadId);
    },
    async [actionTypes.common.parseFile](store, {fileType, file}) {
        if (fileType == null || fileType === '') {
            if (_.endsWith(file.name.toLowerCase(), 'dbf')) {
                fileType = 'dbf';
            } else if (_.endsWith(file.name.toLowerCase(), 'xls') || _.endsWith(file.name.toLowerCase(), 'xlsx')) {
                fileType = 'excel';
            }
        }

        let url = null;
        switch (fileType) {
            case 'excel':
                url = '/parse_excel';
                break;
            case "dbf":
                url = '/parse_dbf';
                break;
            default:
                throw new Error(`FileType ${fileType} not support`);
        }

        const formData = new FormData();
        formData.append('name', file.name);
        formData.append('file', file);
        const {data} = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    },
    async [actionTypes.common.parseExcel]({dispatch}, file) {
        return await dispatch(actionTypes.common.parseFile, {fileType: 'excel', file})
    },
    async [actionTypes.common.parseDbf]({dispatch}, file) {
        return await dispatch(actionTypes.common.parseFile, {fileType: 'dbf', file})
    },
    [actionTypes.common.openDownloadLink](store, downloadId) {
        window.open(`/api/download/${downloadId}`);
    },
    async [actionTypes.common.getWebSettings]({commit}) {
        const {data} = await axios.get('/web_settings');
        commit(mutationTypes.global.setWebSettings, data);
    },
    [actionTypes.common.downloadTemplate](store, baseUrl) {
        baseUrl = _.startsWith(baseUrl, '/') ? baseUrl : `/${baseUrl}`;
        window.open(`/api${baseUrl}/download_template`);
    },
};

export default {
    state,
    mutations,
    actions,
};
