import {actionTypes} from "@/store/actionTypes";
import axios from 'axios';

const baseUrl = '/statistics';

const actions = {
    async [actionTypes.statistics.sourceLine](store, {nf, provinceId}) {
        const {data} = await axios.get(`${baseUrl}/source_line/${nf}/${provinceId}`);
        _.forEach(data, n => {
            if (n.lqlb == null || n.lqlb === '') {
                n.lqlb = '-';
            }
        });
        return data;
    },
    async [actionTypes.statistics.fs](store, condition) {
        const {data} = await axios.post(`${baseUrl}/fs`, condition);
        return data;
    },
    async [actionTypes.statistics.pw](store, condition) {
        const {data} = await axios.post(`${baseUrl}/pw`, condition);
        return data;
    },
    async [actionTypes.statistics.pwExport]({dispatch}, condition) {
        const {data} = await axios.post(`${baseUrl}/pw_export`, condition);
        dispatch(actionTypes.common.openDownloadLink, data, {root: true});
    },
    async [actionTypes.statistics.zymzl](store, condition) {
        const {data} = await axios.post(`${baseUrl}/zymzl`, condition);
        return data;
    },
    async [actionTypes.statistics.groupByNfLqlbSf](store, query) {
        const {data} = await axios.post(`${baseUrl}/group_by_nf_lqlb_sf`, query);
        return data;
    },
    async [actionTypes.statistics.zyrd](store, query) {
        const {data} = await axios.post(`${baseUrl}/zyrd`, query);
        return data;
    },
    async [actionTypes.statistics.common](store, query) {
        const q = _.cloneDeep(query);
        q.groupByFields = _.map(q.groupByFields, n => _.snakeCase(n));

        const {data} = await axios.post(`${baseUrl}/common`, q);
        const result = [];
        for (let d of data) {
            const item = {};
            for (let i = 0; i < 20; i++) {
                if (d[`groupValue${i}`] != null) {
                    item[query.groupByFields[i]] = d[`groupValue${i}`];
                }
                item.total = d.total;
            }
            if (Object.keys(item).length > 0) {
                result.push(item);
            }
        }

        return result;
    },
};

export default {
    namespaced: true,
    actions,
};
