<template>
    <el-container style="height: 100%; width: 100%;">
        <el-header class="top" :height="$store.state.global.headerHeight + 'px'">
            <top/>
        </el-header>
        <el-container class="main-frame">
            <el-aside width="200px">
                <left-menu :root-menu="routers"/>
            </el-aside>
            <el-main style="padding: 0 5px 0 5px">
                <router-view/>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import Top from './Top';
    import LeftMenu from './LeftMenu';
    import {routers} from '@/router';
    import {actionTypes} from "@/store/actionTypes";

    export default {
        components: {LeftMenu, Top},
        data() {
            return {
                routers,
                BUILD_DATE: process.env.BUILD_DATE || 'dev mode',
            };
        },
        mounted() {
            this.$store.commit('calcViewHeight');
            this.$store.dispatch(`yxxq/${actionTypes.common.list}`);
        },
    };
</script>
